import * as React from 'react';
import { Text, MainContainer, Link, SEO } from '~/components';
import styled from '@emotion/styled';
import { useDeliveryInfo, DAYS_TEXT } from '~/provider/DeliveryInfoProvider';
import { css } from '@emotion/react';

const sectionTitle = (theme) =>
  css({
    color: theme.colors.sand,
  });

const Title = styled(Text)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const Section = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(4),
  '&:first-of-type': {
    marginTop: theme.spacing(0),
  },
}));

const SectionText = styled(Text)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const HighligtedText = styled(Text)(({ theme }) => ({
  color: theme.colors.primary.greene,
  marginTop: theme.spacing(1),
  fontWeight: 'bold',
}));

const DeliveryTimeItem = styled.div(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

function Delivery() {
  const { deliveryTimes } = useDeliveryInfo();

  return (
    <>
      <SEO
        title="Lieferung"
        description="Wir liefern am Wochenende am Abend in den Kreisen 1 bis 10 der Stadt Zürich."
      />
      <MainContainer
        css={(theme) => ({
          alignItems: 'center',
          textAlign: 'center',
          color: theme.colors.sand,
        })}
      >
        <h1
          css={(theme) => ({
            color: theme.colors.rose,
            textTransform: 'uppercase',
            textAlign: 'center',
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(2),
          })}
        >
          Lieferinfos
        </h1>

        <Section>
          <h2 css={sectionTitle}>Lieferzeiten</h2>
          {deliveryTimes && (
            <SectionText>
              {Object.keys(DAYS_TEXT).map((day) => {
                const dayDeliveryInfo = deliveryTimes[day];

                if (dayDeliveryInfo) {
                  return (
                    <DeliveryTimeItem key={DAYS_TEXT[day]}>
                      {`${DAYS_TEXT[day]}: ${dayDeliveryInfo
                        .map((dh) => `${dh.start} bis ${dh.end}`)
                        .join(', ')}`}
                    </DeliveryTimeItem>
                  );
                }
              })}
            </SectionText>
          )}
          <HighligtedText>
            Für individuelle Lieferungen ausserhalb der Lieferzeiten, kann
            direkt mit uns{' '}
            <Link withUnderline to="/kontakt">
              Kontakt
            </Link>{' '}
            aufgenommen werden.
          </HighligtedText>
        </Section>
        <Section>
          <h2 css={sectionTitle}>Liefergebiet</h2>

          <SectionText>
            Unser Liefergebiet umfasst die Kreise 1 bis 10 in der Stadt Zürich.
            <br />
            Postleitzahlen: 8001, 8002, 8003, 8004, 8005, 8006, 8008, 8032,
            8037, 8038, 8044, 8045, 8047, 8049, 8055, 8048
          </SectionText>
        </Section>
        <Section>
          <h2 css={sectionTitle}>Liefergebühren</h2>
          <SectionText>
            Für Bestellungen unter CHF 59.00 wird eine Liefergebühr von{' '}
            <b>CHF 15.00</b> erhoben.
          </SectionText>
        </Section>
        <Section>
          <h2 css={sectionTitle}>Mindestbestellung</h2>
          <SectionText>
            Wir liefern ab einem 'Ichaufsbütel' von <b>CHF 30.00</b>.
          </SectionText>
        </Section>
      </MainContainer>
    </>
  );
}

export default Delivery;
